angular.module('mainApp', [
  'ngRoute',
  'ngCookies',
  'ngResource',
  'ngSanitize',
  'ng.deviceDetector',
  'angular-loading-bar',
  'sahibinden.router',
  'webStorageModule',
  'angularFileUpload',
  'ui.select',
  'treeControl',
  'recipesFilters',
  'mainApp.utils.lodash',
  'mainApp.formUtils',
  'angularMoment',
  'fitVids',
  'nsPopover',
  'ngDialog',
  'vAccordion',
  'ngAnimate',
  'MassAutoComplete',
  'jtt_youtube',
  'afkl.lazyImage',
  'ngCart',
  'angular-inview',
  'ngclipboard',
  'slickCarousel',
  'ngMaterial',
  'ngMessages',
  'ngYoutube'
]); //'infinite-scroll',, 'ngRaven' || 'puigcerber.capitalize', 'angulartics', 'angulartics.google.tagmanager',
